import React from 'react';

const ReturnPolicy = () => {
  return (
    <div>
    <>
      <div className="site-primary" id="main">
        <div className="site-content" id="content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={3553}
            className="elementor elementor-3553"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-1f85b46 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
              data-id="1f85b46"
              data-element_type="section"
              data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-483c388"
                  data-id="483c388"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-f0c0ac7 elementor-widget elementor-widget-heading"
                      data-id="f0c0ac7"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-heading-title {\n\t\t\t\t\t\t\t\t\t\t\t\t\tpadding: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin: 0;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: inherit;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: inherit\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-small {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 15px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-medium {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 19px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-large {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 29px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 39px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 59px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                          }}
                        />
                        <h2 className="elementor-heading-title elementor-size-default mt-5">
                          Return &amp; Cancellaton Policy
                        </h2>
                        <a
                          className="btn partdo-btn mt-3"
                          href="https://motortiger.com/super-files/motortiger-return-and-cancellaton-policy.pdf"
                        >
                          Download Return &amp; Cancellaton Policy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-4220277 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id={4220277}
              data-element_type="section"
              data-settings='{"_ha_eqh_enable":false}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a83622"
                  data-id="5a83622"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-fc1ced0 elementor-widget elementor-widget-text-editor"
                      data-id="fc1ced0"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "\n\t\t\t\t\t\t\t\t\t\t\t\t/*! elementor - v3.22.0 - 26-06-2024 */\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #fff\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\tcolor: #69727d;\n\t\t\t\t\t\t\t\t\t\t\t\t\tborder: 3px solid;\n\t\t\t\t\t\t\t\t\t\t\t\t\tbackground-color: transparent\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\tmargin-top: 8px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\twidth: 1em;\n\t\t\t\t\t\t\t\t\t\t\t\t\theight: 1em\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap {\n\t\t\t\t\t\t\t\t\t\t\t\t\tfloat: left;\n\t\t\t\t\t\t\t\t\t\t\t\t\ttext-align: center;\n\t\t\t\t\t\t\t\t\t\t\t\t\tline-height: 1;\n\t\t\t\t\t\t\t\t\t\t\t\t\tfont-size: 50px\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\n\t\t\t\t\t\t\t\t\t\t\t\t.elementor-widget-text-editor .elementor-drop-cap-letter {\n\t\t\t\t\t\t\t\t\t\t\t\t\tdisplay: inline-block\n\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t"
                          }}
                        />
                        <p>
                          <a href="https://motortiger.com/">
                            At <b>Motor Tiger Inc.</b>
                          </a>
                          ,we are committed to providing high-quality auto parts and
                          ensuring a seamless shopping experience. However, we
                          understand that sometimes products may not meet your
                          needs. Below are our return, cancellation, and shipping
                          policies to guide you through the process:
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-588b67e elementor-widget elementor-widget-heading"
                      data-id="588b67e"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Cancellation Terms and Conditions
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-8bd3a47 elementor-widget elementor-widget-text-editor"
                      data-id="8bd3a47"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                          <li>
                            A).Before Shipment: Orders can be cancelled only before
                            they are shipped.
                          </li>
                          <li>
                            <ul>
                              <li>
                                a) If you cancel an order before shipment, within 24
                                hrs. of booking. In this case, a nominal processing
                                fee of 5% will be charged on the total amount paid.
                                The rest amount will be refunded back.
                              </li>
                              <li>
                                b) In case, if you cancel an order after 24 hrs. of
                                booking but before the order is shipped. Following
                                charges will be applicable : A restocking fees of
                                25% will be charged on the total amount paid. The
                                rest amount will be refunded back.
                              </li>
                            </ul>
                          </li>
                          <li>
                            B).After Shipment: Once an order has been shipped,
                            cancellation is no longer possible. If the order has
                            already left our facility, no cancellation will be
                            processed, and the cancelled amount will not be
                            refunded.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-588b67e elementor-widget elementor-widget-heading"
                      data-id="588b67e"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          No Refund on Shipping
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-be38280 elementor-widget elementor-widget-text-editor"
                      data-id="be38280"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Shipping Charges: Shipping charges are non-refundable.
                          Customers are responsible for any additional shipping
                          costs, including extra charges incurred during delivery.
                          <br />
                          Returns: Only the cost of the part will be refunded in the
                          event of a return or refund request. Shipping costs and
                          related expenses will not be reimbursed.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-0bce31e elementor-widget elementor-widget-heading"
                      data-id="0bce31e"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Used Parts Disclaimer
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-539bcd6 elementor-widget elementor-widget-text-editor"
                      data-id="539bcd6"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Motor Tiger Inc. sources auto parts from a network of over
                          5,000 inventories across the United States. Although we
                          inspect all parts for functionality, we are not
                          responsible for cosmetic imperfections such as rust,
                          slight colour variations, or minor wear and tear. These
                          conditions are typical for pre-owned items and do not
                          affect the performance of the part.
                        </p>
                        <p>
                          <b>Customer Responsibility</b>
                          It is the customer’s responsibility to evaluate the part's
                          suitability based on its condition and functionality. If
                          you require more detailed information about a part's
                          history or condition, please specify to us before making a
                          purchase.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-e4b7fc2 elementor-widget elementor-widget-heading"
                      data-id="e4b7fc2"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Return, Cancellaton & Shipping Policy - 15 Days
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-3e27bf9 elementor-widget elementor-widget-text-editor"
                      data-id="3e27bf9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Return Window: Returns must be made within 10-15 days of
                          purchase. Motor Tiger Inc. will not accept returns made
                          after this period or for packages that are lost or stolen
                          during return shipping.
                        </p>
                        <p>
                          Lost or Stolen Packages: If your return is lost or stolen
                          during shipping, please file a claim directly with the
                          shipping company.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Shipping Costs and Fees / Residential Delivery Terms
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-23d4494 elementor-widget elementor-widget-text-editor"
                      data-id="23d4494"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <ul>
                          <li>
                            <b>Additional Fees:</b>Residential deliveries typically
                            incur extra shipping charges. These fees are necessary
                            due to factors such as the size, weight, or special
                            handling requirements of auto parts. The exact fee will
                            be calculated at checkout and added to your order total
                            amount
                          </li>
                          <li>
                            <b>Delivery Access:</b> Residential deliveries may
                            require additional coordination, especially for large or
                            heavy parts, such as engines or transmissions. This may
                            include restricted access areas, narrow driveways, or
                            limited vehicle access. If such conditions apply,
                            additional charges may be assessed.
                          </li>
                          <li>
                            <b>Liftgate Service:</b> For large or heavy parts that
                            cannot be manually unloaded, lift gate service may be
                            required. This service brings the part to the ground
                            from the delivery truck's tailgate. A separate fee,
                            typically between $150-$200, may apply depending on the
                            location
                          </li>
                          <li>
                            <b>Non-refundable Charges:</b> Any additional charges
                            associated with residential delivery, including liftgate
                            service, access fees, or other special handling fees,
                            are non-refundable. These charges are the responsibility
                            of the customer and will not be reimbursed in the event
                            of a return or cancellation.
                          </li>
                          <li>
                            <b>Delivery Time Window:</b> Residential deliveries may
                            have a longer transit time than shipments to business
                            addresses or freight terminals. While we strive to
                            ensure timely delivery, please be aware that residential
                            addresses may experience delays, particularly for larger
                            parts or locations with limited access.
                          </li>
                          <li>
                            <b>Customer Responsibility:</b> It is the customer’s
                            responsibility to ensure that the delivery location is
                            accessible for the size and weight of the auto parts
                            being delivered. Any access restrictions or failed
                            delivery attempts may result in additional fees.
                          </li>
                        </ul>
                        <p>
                          Other Delivery Charges: Any delivery-related charges
                          (e.g., limited access fees or miscellaneous charges) will
                          be the responsibility of the customer and are
                          non-refundable.
                        </p>
                        <p>
                          Expedited Shipping Clause: Motor Tiger offers expedited
                          shipping options to customers who need faster delivery.
                          Below are the details regarding expedited shipping:
                        </p>
                        <ul>
                          <li>
                            <b>Availability:</b>
                            Expedited shipping is available for certain products and
                            locations. Not all orders may be eligible for expedited
                            shipping due to the nature of the part or shipping
                            destination.
                          </li>
                          <li>
                            <b>Shipping Times:</b>
                            Expedited shipping guarantees a faster delivery
                            timeframe than standard shipping. The exact delivery
                            window will depend on the shipping service chosen and
                            the destination.
                          </li>
                          <li>
                            <b>Additional Costs:</b>
                            Expedited shipping incurs additional charges, which will
                            be clearly communicated during the purchase. These
                            charges are non-refundable, even in the case of returns.
                          </li>
                          <li>
                            <b>Order Processing Time:</b>
                            Orders placed with expedited shipping are prioritized
                            for quicker processing. However, please note that
                            expedited shipping only affects the delivery speed and
                            not the processing time at our facility. Orders must
                            still be processed, and inventory must be available
                            before shipping can occur.
                          </li>
                          <li>
                            <b>Non-refundable Shipping Charges:</b>
                            The additional costs associated with expedited shipping
                            are non-refundable, even if a return or cancellation is
                            made.
                          </li>
                        </ul>
                        <p>
                          If you require expedited shipping, please specify during
                          your purchase in determining the best shipping method for
                          your needs.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Other Expenses
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0df1c9 elementor-widget elementor-widget-text-editor"
                      data-id="b0df1c9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Motor Tiger Inc. does not reimburse installation costs,
                          labour charges, towing fees, or any other expenses beyond
                          the cost of the auto part itself.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Damaged Product
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0df1c9 elementor-widget elementor-widget-text-editor"
                      data-id="b0df1c9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          <b>Report Damage:</b> Any product damage must be reported
                          within 24 hours of delivery. If the part is damaged or has
                          visible cracks, please contact us immediately.
                          <br />
                          <br />
                          <b>Failure to Report:</b> If damage is not reported within
                          the 24-hour window, Motor Tiger Inc. will not be
                          responsible for the damage.
                          <br />
                          <br />
                          <b>Shipping Damage:</b> Any shipping damage must be
                          reported to the shipping company within 24 hours. We are
                          not responsible for damages caused during shipping.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Warranty and Credit Card Consent forms
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0df1c9 elementor-widget elementor-widget-text-editor"
                      data-id="b0df1c9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          <b>Forms Required:</b> The warranty and credit card
                          consent forms must be signed and dated within 48 hours.
                          Failure to return the signed forms will result in shipping
                          delays and may void the warranty.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          Mileage Disclaimer
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0df1c9 elementor-widget elementor-widget-text-editor"
                      data-id="b0df1c9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Motor Tiger Inc. is a parts-locating company and does not
                          guarantee the mileage or condition of parts. We partner
                          with over 5,000 inventories across the United States to
                          source reasonably used engines and transmissions. While we
                          test the parts to ensure quality, mileage is not
                          guaranteed.
                        </p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-015452d elementor-widget elementor-widget-heading"
                      data-id="015452d"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          How to File a Refund Request
                        </h2>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0df1c9 elementor-widget elementor-widget-text-editor"
                      data-id="b0df1c9"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Contact Customer Service: Reach out to our customer
                          service team for assistance at{" "}
                          <a href="tel:(855) 253-1634">+1 (855) 253-1634</a> or via
                          email
                          <a href="mailto:info@motortiger.com">
                            info@motortiger.com
                          </a>
                          <br />
                          <br />
                          Online Refund Request: Alternatively, you can visit our
                          website:{" "}
                          <a href="https://www.motortiger.com">
                            www.motortiger.com
                          </a>{" "}
                          log in, and submit a refund request by entering your order
                          number and product details.
                          <br />
                          <br />
                          Damaged or Incorrect Parts: If the part is damaged or
                          doesn’t fit, please share clear photos of the part to
                          support your refund request.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* #page */}
      </div>
      {/* #page */}
    </>
    </div>
  );
};

export default ReturnPolicy;